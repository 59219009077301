<template>
    <b-modal ref="modal" title="Dodaj občino" centered no-close-on-backdrop @cancel="makeEmptyObject" @close="makeEmptyObject" @hide="makeEmptyObject">
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Ime">
                    <validation-provider #default="{errors}" name="Ime" rules="required|min:1|max:50">
                        <b-form-input v-model="addMunicipalityObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Opis">
                    <validation-provider #default="{errors}" name="Opis" rules="max:1000">
                        <b-form-textarea v-model="addMunicipalityObject.description"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox switch v-model="addMunicipalityObject.child_allowance_required">Potreben otroški dodatek</b-form-checkbox>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer="{ hide }">
            <b-button variant="outline-primary" @click="hide">
                Zapri
            </b-button>
            <b-button variant="primary" @click="add">
                Dodaj
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import {BButton, BModal, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {required, min, max} from '@core/utils/validations/validations'

    export default {
        components: {
            BFormCheckbox,
            BModal,
            BButton,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                addMunicipalityObject: {
                    name: '',
                    description: '',
                    child_allowance_required: false
                },
                required,
                min,
                max
            }
        },
        methods: {
            show() {
                this.$refs.modal.show()
            },
            async add() {
                const isValid = await this.$refs.validation.validate()
                if (!isValid) return

                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.post('/api/private/v1/municipality/', this.addMunicipalityObject)
                    this.$refs.modal.hide()
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno')
                    this.makeEmptyObject()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri dodajanju')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            makeEmptyObject() {
                this.addMunicipalityObject = this.$options.data.call(this).addMunicipalityObject
            }
        }
    }
</script>
